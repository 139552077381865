import styled from "styled-components";

import { COLORS } from "../../constants/colors";

export const ButtonStyled = styled.button`
  width: 100%;
  max-width: 251px;
  height: 53px;
  padding: 16px 24px;
  background-color: ${COLORS.GREEN.MAIN};
  color: ${COLORS.WHITE};
  font-size: 1.12em;

  ${({ theme }) => theme.media.x`
    font-size: 1em;
  `}
`;