import React from "react";
import scrollTo from "gatsby-plugin-smoothscroll";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import CustomBackground from "../../components/customBackground";
import Text from "../../components/text";
import { CardBroken } from "../../components/icons";
import { pathID } from "../../components/header";

import {
  HomeContent,
  Title,
  Wrapper,
  Divider,
  Description,
  ActionButton,
  BoldTitle,
  DescriptionWrapper,
  AboutWrapper,
  AboutContent,
  AboutTitle,
  ImageContent,
  ButtonWrapper,
  ScheduleConversationButton,
} from "./styles";

const Home = () => {
  const imageData = useStaticQuery(graphql`
    query {
      imageSharp(fixed: { originalName: { eq: "about_screens.png" } }) {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `);

  return (
    <Wrapper id="home">
      <CustomBackground>
        <HomeContent>
          <CardBroken />
          <Title component="h1">WE ARE</Title>
          <BoldTitle component="h1">CARDLESS</BoldTitle>
          <DescriptionWrapper>
            <Divider />
            <Description>
              Somos uma fintech <strong>nichada para agências e plataformas de incentivo</strong>{" "}
              focada em <strong>digitalizar todo o processo de pagamentos das campanhas.</strong>
            </Description>
            <ActionButton onClick={() => scrollTo(pathID[4].item)}>
              Solicitar demonstração
            </ActionButton>
          </DescriptionWrapper>
        </HomeContent>
      </CustomBackground>
      <AboutWrapper>
        <AboutContent>
          <AboutTitle component="h2">
            Acelere a distribuição de incentivos facilmente <strong>através de tecnologia.</strong>
          </AboutTitle>
          <Text component="p">
            <strong>Digital Cash Manager:</strong> Digitalizamos as rotinas de tesouraria da sua área administrativa para o controle e execução de múltiplas campanhas. 
          </Text>

          <Text component="p">
            <strong>WebVoucher:</strong> Proporcionamos o saque pelo incentivado em tempo real, via PIX, pelo WhatsApp. 
          </Text>

          <Text component="p">
            <strong>Podemos conversar um pouco para que te apresentar a Fitce?</strong>
          </Text>
          
          <ButtonWrapper>
            <ScheduleConversationButton onClick={() => scrollTo(pathID[4].item)}>Marque uma conversa</ScheduleConversationButton>
          </ButtonWrapper>
        </AboutContent>
        <ImageContent>
          <Img fluid={imageData.imageSharp.fluid} />
        </ImageContent>
      </AboutWrapper>
    </Wrapper>
  );
};

export default Home;
