import React from "react";

import {
  ArrowDropdownStyled,
  ArrowDropdownStyledMobie,
  ArrowRightStyle,
  OrientationWrapper,
  CardItem,
  TextStyled,
  BagIcon,
  ContactIcon,
  CardCheckIcon,
  TransportIcon,
  BadgeIcon,
  MailIcon,
  LockOpenIcon,
  PriceCheckIcon,
} from "../styles";

import { CardsWrapper, DropdownWrapper, DropdownWrapperMobile } from "./styles";

const OldMethod = () => {
  return (
    <OrientationWrapper>
      <CardsWrapper>
        <CardItem>
          <BagIcon />
          <TextStyled component="span">Contratação do serviço</TextStyled>
        </CardItem>
        <div>
          <ArrowRightStyle />
        </div>
        <CardItem>
          <ContactIcon />
          <TextStyled component="span">
            Recebimento dos dados dos beneficiários
          </TextStyled>
        </CardItem>
        <div>
          <ArrowRightStyle />
        </div>
        <CardItem>
          <CardCheckIcon />
          <TextStyled component="span">
            Vinculação dos dados nos cartões
          </TextStyled>
        </CardItem>
        <div>
          <ArrowRightStyle />
        </div>
        <CardItem>
          <TransportIcon />
          <TextStyled component="span">
            Envio dos cartões para o contratante
          </TextStyled>
        </CardItem>
        <div>
          <ArrowRightStyle />
        </div>
        <CardItem>
          <BadgeIcon />
          <TextStyled component="span">
            Distribuição de cartões pelo RH
          </TextStyled>
        </CardItem>
        <div>
          <ArrowRightStyle />
        </div>
        <CardItem>
          <MailIcon />
          <TextStyled component="span">Envio dos cartões</TextStyled>
        </CardItem>
        <div>
          <ArrowRightStyle />
        </div>
        <CardItem>
          <LockOpenIcon />
          <TextStyled component="span">
            Beneficiário desbloqueia o cartão
          </TextStyled>
        </CardItem>
        <div>
          <ArrowRightStyle />
        </div>
        <CardItem>
          <PriceCheckIcon />
          <TextStyled component="span">
            Beneficiário acessa o incentivo
          </TextStyled>
        </CardItem>
      </CardsWrapper>
      <DropdownWrapper>
        <ArrowDropdownStyled />
      </DropdownWrapper>

      <DropdownWrapperMobile>
        <ArrowDropdownStyledMobie />
      </DropdownWrapperMobile>
      <DropdownWrapperMobile>
        <ArrowDropdownStyledMobie />
      </DropdownWrapperMobile>
      <DropdownWrapperMobile>
        <ArrowDropdownStyledMobie />
      </DropdownWrapperMobile>
      <DropdownWrapperMobile>
        <ArrowDropdownStyledMobie />
      </DropdownWrapperMobile>
    </OrientationWrapper>
  );
};

export default OldMethod;
