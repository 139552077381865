import React from "react";
import scrollTo from "gatsby-plugin-smoothscroll";

import { pathID } from "../../components/header";
import Timeline from "../../components/timeline";
import Text from "../../components/text";

import {
  ActionButton,
  ContactText,
  ContactWrapper,
  Content,
  HeadingStyle,
  HeadingHighlight,
  ImageWrapper,
  ListItem,
  ListWrapper,
  Subtitle,
  TextContent,
  TextHighlight,
  TextWrapper,
  Wrapper,
  BGTransition,
  TitleWrapper,
} from "./styles";
import { Message, Spreadsheet } from "../../components/icons";

const Solutions = () => {
  return (
    <Wrapper id="solutions">
      <BGTransition>
        <Content variant="type1">
          <HeadingStyle component="h2" highlight>
            Adeus burocracias
          </HeadingStyle>
          <HeadingStyle component="h2">dos cartões de vantagens</HeadingStyle>
          <TextContent>
            <Text component="p">
              Gerenciar os cartões de incentivo são, de longe, os maiores custos
              e burocracias enfrentados no pagamento de incentivos.
            </Text>

            <Text component="p">
              Pois, além de monitorar a compra, a impressão e a logística de
              entrega, é necessário ter um suporte sempre disponível para
              gerenciar problemas com senhas, perdas e extravios.
            </Text>

            <Text component="p">
              <strong>
                Por isso, somos a primeira plataforma a entregar o poder do{" "}
                <TextHighlight>WebVoucher</TextHighlight> para eliminar 100% dos
                cartões plásticos e atendimento aos beneficiários com o poder da
                inteligência artificial.
              </strong>
            </Text>
          </TextContent>
        </Content>

        <Content variant="type2">
          <Timeline />
        </Content>

        <Content variant="type3">
          <TextWrapper>
            <HeadingStyle component="h2" highlight>
              Digital Cash Manager
            </HeadingStyle>
            <Subtitle component="h4">
              Plataforma altamente escalável e <i>low-code.</i>
            </Subtitle>
            <Text component="p">
              Gestão de caixa com um número reduzido de funcionários
            </Text>
            <ListWrapper>
              <ListItem>Cadastro de beneficiários em lote;</ListItem>
              <ListItem>Distribuição de incentivos em lote;</ListItem>
              <ListItem>Controle das contas delegadas;</ListItem>
              <ListItem>Relatórios automatizados;</ListItem>
              <ListItem>Comunicado de incentivo personalizado; e</ListItem>
              <ListItem>Ativação de conta dos usuários em lote.</ListItem>
            </ListWrapper>
          </TextWrapper>
          <ImageWrapper>
            <Spreadsheet style={{ width: "100%" }} />
          </ImageWrapper>
        </Content>

        <Content variant="type4">
          <ImageWrapper>
            <Message style={{ width: "100%" }} />
          </ImageWrapper>
          <TextWrapper>
            <TitleWrapper>
              <HeadingStyle component="h2" highlight>
                WebVoucher
              </HeadingStyle>
              <HeadingStyle component="h2">
                Chegou o incentivo em tempo real
              </HeadingStyle>
            </TitleWrapper>

            <Text component="p">
              Reter talentos é o novo desafio das grandes corporações, por isto,
              incentivar colaboradores em tempo real com base em seu desempenho
              é a aposta do momento.{" "}
            </Text>

            <Text component="p">
              Com a <strong>FITCE</strong>, além de fazer bonificações em menos
              de <strong>3 minutos</strong>, o colaborador recebe
              instantaneamente o acesso ao incentivo no app de mensagens mais
              popular do mundo, o <strong>WhatsApp</strong>.
            </Text>
          </TextWrapper>
        </Content>

        <Content variant="type5">
          <ContactWrapper>
            <ContactText component="h2">
              Solicite uma demonstração e conte com a nossa ajuda para construir
              uma nova experiência de pagamento de incentivos e outros contextos
            </ContactText>
            <ActionButton onClick={() => scrollTo(pathID[4].item)}>
              Marque uma conversa
            </ActionButton>
          </ContactWrapper>
        </Content>
      </BGTransition>
    </Wrapper>
  );
};

export default Solutions;
