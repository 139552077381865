import styled from "styled-components";
import Heading from "../../components/heading";
import { COLORS } from "../../constants/colors";

export const Wrapper = styled.section`
  background-color: ${COLORS.WHITE};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 80px 0;
  gap: 67px;
  transition: ease 0.2s;

  ${({ theme }) => theme.media.x`
    padding: 66px 24px;
  `}

  ${({ theme }) => theme.media.md`
    padding: 24px;
    gap: 32px;
  `}
`;

export const HeadingStyle = styled(Heading)`
  text-align: center;

  ${({ theme }) => theme.media.sm`
    max-width: 200px;
  `}
`;

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: 912px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
