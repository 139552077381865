import styled from "styled-components";
import { COLORS } from "../../constants/colors";
import Heading from "../heading";
import { ArrowDown } from "../icons";
import Text from "../text";

interface IAccordion {
  hasOpen: boolean;
  maxH?: number;
  maxHMobile?: number;
}

export const Wrapper = styled.div<IAccordion>`
  background-color: ${COLORS.WHITE};
  width: 100%;
  height: ${({ hasOpen, maxH }) =>
    hasOpen ? (maxH ? `${maxH}px` : "fit-content") : "72px"};
  overflow: hidden;
  transition: ease 0.2s;
  margin-top: 16px;

  ${({ theme, hasOpen, maxHMobile }) => theme.media.md`
    height: ${
      hasOpen ? (maxHMobile ? `${maxHMobile}px` : "fit-content") : "72px"
    };
  `}
`;

export const TitleWrapper = styled.div`
  width: 100%;
  background-color: ${COLORS.GRAY["-3"]};
  padding: 20px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 72px;
  cursor: pointer;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const HeadingStyle = styled(Heading)`
  color: ${COLORS.GRAY["+1"]};
  font-weight: 400;
  font-size: 1.125em;
  line-height: 32px;

  ${({ theme }) => theme.media.x`
    font-size: 0.925em;
  `}

  ${({ theme }) => theme.media.md`
    font-size: 1em;
  `}
`;

export const ArrowClick = styled.button<IAccordion>`
  background-color: ${COLORS.GRAY["-3"]};
  transform: rotate(180deg);
  transform: ${({ hasOpen }) =>
    hasOpen ? "rotate(180deg)" : "rotate(360deg)"};

  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    filter: none;
  }
`;

export const ContentWrapper = styled.div`
  background-color: ${COLORS.WHITE};
  padding: 14px 24px;
  border: 1px solid ${COLORS.GRAY["-3"]};
  height: fit-content;
`;

export const Description = styled(Text)``;

export const ArrowDownIcon = styled(ArrowDown)``;
