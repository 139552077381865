import styled, { css } from "styled-components";
import Heading from "../../components/heading";
import { CellPhone } from "../../components/icons";
import { COLORS } from "../../constants/colors";

interface IContent {
  variant?: "type1" | "type2" | "type3";
}

export const Wrapper = styled.section`
  background-color: ${COLORS.WHITE};
`;

export const TransitionBg = styled.div`
  width: 100%;
  background-color: ${COLORS.WHITE};
  padding: 126px 0;

  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 42px;

  ${({ theme }) => theme.media.md`
    padding: 60px 0 24px;
  `}
`;

export const HeadingWrapper = styled.div`
  width: 100%;
  max-width: 807px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;
  place-content: center;
`;

export const HeadingStyled = styled(Heading)<{ highlight?: boolean }>`
  font-size: 4em;
  line-height: 76px;

  ${({ highlight }) =>
    highlight &&
    css`
      color: ${COLORS.WHITE};
      background-color: ${COLORS.ORANGE};
      padding: 8px 16px;
    `};

  ${({ theme }) => theme.media.x`
    font-size: 3.2em;
    line-height: 62px;
  `}

  ${({ theme }) => theme.media.md`
    font-size: 2.5em;
    line-height: 47.4px;
  `}
`;

export const Content = styled.div<IContent>`
  width: 100%;
  max-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ variant }) => variant === "type1" && css``};

  ${({ variant }) =>
    variant === "type2" &&
    css`
      gap: 32px;
    `};

  ${({ variant }) =>
    variant === "type3" &&
    css`
      max-width: 911px;
      gap: 57px;
      flex-direction: column;
    `};

  ${({ theme }) => theme.media.x`
    max-width: 1026px;
  `}

  ${({ theme }) => theme.media.lg`
    padding: 0 24px;
  `}

  ${({ theme, variant }) => theme.media.md`
    ${
      variant === "type1" &&
      css`
        flex-direction: column;
      `
    };
    ${
      variant === "type2" &&
      css`
        flex-direction: column;
      `
    };
    ${
      variant === "type3" &&
      css`
        flex-direction: column;
        text-align: center;
        gap: 24px;
        margin-top: 24px;
      `
    };
  `}
`;

export const SubHeading = styled(Heading)``;

export const CardContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 24px;

  ${({ theme }) => theme.media.md`
    flex-direction: column;
    align-items: center;

  `}
`;

export const Card = styled.div`
  background: rgba(0, 0, 0, 0.03);
  width: 100%;
  max-width: 443.5px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 24px 82px;

  ${({ theme }) => theme.media.md`
    max-width: unset;
  `}
`;

export const TextContent = styled.div`
  width: 100%;
  max-width: 512px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: start;

  gap: 32px;
`;

export const HeadingContent = styled(Heading)`
  color: ${COLORS.ORANGE};
  max-width: 488px;
`;

export const ImgContent = styled.div<{ highlight?: boolean }>`
  width: 100%;

  ${({ highlight }) =>
    highlight &&
    css`
      display: flex;
      max-height: 579px;
      /* max-width: 300px; */
      justify-content: space-between;
    `};

  /* ${({ theme }) => theme.media.md`
    overflow: hidden;
    display: inline-block;
  `} */
`;

export const CellPhoneIcon = styled(CellPhone)`
  width: 100%;

  ${({ theme }) => theme.media.md`
    transform: scale(1.3);
    transform-origin: 50% 50%;
    display: block;
    transition: transform .4s;
  `}
`;
