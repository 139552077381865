import React, { ReactNode } from "react";

import { ButtonStyled } from "./styles";

interface IButton {
  children: ReactNode;
  onClick?: () => void;
}

const Button: React.FC<IButton> = ({ children, ...props }) => {
  return <ButtonStyled {...props}>{children}</ButtonStyled>;
};

export default Button;
