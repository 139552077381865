import React, { useEffect, useState } from "react";

import Button from "../../components/button";
import CustomBackground from "../../components/customBackground";
import { COLORS } from "../../constants/colors";

import { Content, HeadingStyle, Wrapper, Anchor } from "./styles";

const Contact = () => {
  const [windowDimensions, setWindowDimensions] = useState(0);

  useEffect(() => {
    function handleResize() {
      const width = window.innerWidth;
      setWindowDimensions(width);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Wrapper id="contact">
      <CustomBackground height="598px" color={COLORS.GRAY.MAIN}>
        <Content>
          <HeadingStyle component="h3">Solicite uma apresentação personalizada</HeadingStyle>
          <Anchor href="mailto:comercial@fitce.com.br" target="_blank">
            comercial@fitce.com.br
          </Anchor>
          {/* <Anchor
            href={
              windowDimensions <= Breakpoints?.md
                ? "https://api.whatsapp.com/send?phone=5511954990222"
                : "https://web.whatsapp.com/send?phone=+5511954990222"
            }
            target="_blank"
          >
            (11) 95499-0222 (WhatsApp)
          </Anchor> */}
          <Button>
            <a href="mailto:comercial@fitce.com.br" target="_blank">
              Marque uma conversa
            </a>
          </Button>
        </Content>
      </CustomBackground>
    </Wrapper>
  );
};

export default Contact;
