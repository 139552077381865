import styled, { css } from "styled-components";

import { COLORS } from "../../constants/colors";
import Text from "../text";

import ArrowRight from "../../assets/images/arrow_right_timeline.svg";
import ArrowDropdown from "../../assets/images/arrow_dropdown.svg";
import ArrowDropdownMobile from "../../assets/images/arrow_dropdown_mobile.svg";
import Heading from "../heading";
import {
  Badge,
  Bag,
  CardCheck,
  Contact,
  LockOpen,
  Mail,
  MobileFriendly,
  PriceCheck,
  Transport,
  Pix,
} from "../icons";

export const HeadingContent = styled(Heading)<{ highlight?: boolean }>`
  font-family: "Inter";
  font-weight: 400;
  line-height: 150%;
  text-align: center;

  margin-bottom: 46px;

  ${({ highlight }) =>
    highlight &&
    css`
      font-weight: 700;
      color: ${COLORS.ORANGE};
      margin-top: 64px;
    `};
`;

export const OrientationWrapper = styled.div`
  width: 100%;
  max-width: 980px;
  display: flex;

  position: relative;

  @media (max-width: 1005px) {
    max-width: 866px;
  }

  @media (max-width: 803px) {
    max-width: 660px;
  }

  ${({ theme }) => theme.media.lg`
    max-width: 434px;
  `};
`;

export const CardItem = styled.div`
  width: 120px;
  height: 98px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
  place-content: start;
`;

export const TextStyled = styled(Text)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875em;
  line-height: unset;
  text-align: center;

  ${({ theme }) => theme.media.x`
    font-size: 0.775em;
    `};

  ${({ theme }) => theme.media.md`
    font-size: 0.875em;
  `}
`;

export const ArrowRightStyle = styled(ArrowRight)``;

export const ArrowDropdownStyled = styled(ArrowDropdown)`
  margin-left: 192px;

  @media (max-width: 1005px) {
    &:nth-child(1) {
      margin-left: 10px;
    }
  }

  @media (max-width: 803px) {
    width: 100%;
  }

  ${({ theme }) => theme.media.lg`
    display: none;
  `};
`;

export const ArrowDropdownStyledMobie = styled(ArrowDropdownMobile)`
  display: none;

  ${({ theme }) => theme.media.lg`
    display: block;
  `};
`;

export const BagIcon = styled(Bag)<{ highlight?: boolean }>`
  fill: ${COLORS.GRAY["+2"]};

  ${({ highlight }) =>
    highlight &&
    css`
      fill: ${COLORS.ORANGE};
    `};
`;

export const ContactIcon = styled(Contact)<{ highlight?: boolean }>`
  fill: ${COLORS.GRAY["+2"]};

  ${({ highlight }) =>
    highlight &&
    css`
      fill: ${COLORS.ORANGE};
    `};
`;
export const CardCheckIcon = styled(CardCheck)<{ highlight?: boolean }>`
  fill: ${COLORS.GRAY["+2"]};
`;

export const TransportIcon = styled(Transport)<{ highlight?: boolean }>`
  fill: ${COLORS.GRAY["+2"]};
`;

export const BadgeIcon = styled(Badge)<{ highlight?: boolean }>`
  fill: ${COLORS.GRAY["+2"]};
`;

export const MailIcon = styled(Mail)<{ highlight?: boolean }>`
  fill: ${COLORS.GRAY["+2"]};
`;

export const LockOpenIcon = styled(LockOpen)<{ highlight?: boolean }>`
  fill: ${COLORS.GRAY["+2"]};
`;

export const MobileFriendlyIcon = styled(MobileFriendly)<{
  highlight?: boolean;
}>`
  fill: ${COLORS.GRAY["+2"]};

  ${({ highlight }) =>
    highlight &&
    css`
      fill: ${COLORS.ORANGE};
    `};
`;

export const PriceCheckIcon = styled(PriceCheck)<{
  highlight?: boolean;
}>`
  fill: ${COLORS.GRAY["+2"]};

  ${({ highlight }) =>
    highlight &&
    css`
      fill: ${COLORS.ORANGE};
    `};
`;

export const PixIcon = styled(Pix)<{
  highlight?: boolean;
}>`
  fill: ${COLORS.GRAY["+2"]};

  ${({ highlight }) =>
    highlight &&
    css`
      fill: ${COLORS.ORANGE};
    `};
`;
