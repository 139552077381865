import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import Text from "../../components/text";

import {
  Card,
  CardContent,
  Content,
  HeadingStyled,
  HeadingContent,
  HeadingWrapper,
  ImgContent,
  SubHeading,
  TextContent,
  TransitionBg,
  Wrapper,
  CellPhoneIcon,
} from "./styles";
import { Person, Suitcase } from "../../components/icons";

const PaymentMethods = () => {
  const paymentMethodList = useStaticQuery(graphql`
    query Methods {
      allFile(
        filter: {
          extension: { regex: "/(png)/" }
          relativeDirectory: { eq: "paymentMethods" }
        }
      ) {
        edges {
          node {
            base
            childImageSharp {
              fluid {
                aspectRatio,
                src,
                srcSet,
                sizes
              }
            }
          }
        }
      }
    }
  `);

  return (
    <Wrapper id="paymentMethods">
      <TransitionBg>
        <HeadingWrapper>
          <HeadingStyled component="h1">Ficou mais fácil</HeadingStyled>
          <HeadingStyled component="h1" highlight>
            incentivar
          </HeadingStyled>
        </HeadingWrapper>
        <Content
          variant="type1"
          style={{
            justifyContent: "space-between",
          }}
        >
          <TextContent>
            <HeadingContent component="h2">
              Delegue a gestão da conta ao beneficiário
            </HeadingContent>

            <Text component="p">
              Garanta aos beneficiários acesso a uma{" "}
              <strong>”digital banking account”</strong> que pode ser
              autenticada em poucos minutos.
            </Text>
            <Text component="p">
              <strong>Gestão 100% digital,</strong> sem cartão, sem burocracia e
              sem custos operacionais para recuperar a senha e desbloquear
              conta.
            </Text>
          </TextContent>
          <ImgContent highlight>
            <Img
              style={{flex: 1}}
              imgStyle={{objectFit: "contain"}}
              fluid={
                paymentMethodList.allFile.edges[0].node.childImageSharp.fluid
              }
            />
          </ImgContent>
        </Content>
        <Content variant="type1">
          <TextContent>
            <HeadingContent component="h2">
              Sua campanha de incentivo, seu visual
            </HeadingContent>
            <Text component="p">
              <strong>Personalize as cores e o logo do seu WebVoucher,</strong>{" "}
              desenhado para oferecer a melhor experiência mobile e agilizar o
              acesso ao resgate de incentivo
            </Text>
          </TextContent>
          <ImgContent highlight>
            <Img
              style={{flex: 1}}
              imgStyle={{objectFit: "contain"}}
              fluid={
                paymentMethodList.allFile.edges[2].node.childImageSharp.fluid
              }
            />
          </ImgContent>
        </Content>
        <Content variant="type1">
          <TextContent>
            <HeadingContent component="h2">
              Incentivo de verdade com liberdade de escolha
            </HeadingContent>
            <Text component="p">
              Com o <strong>WebVoucher</strong> ofereça independência total ao
              beneficiário para movimentar o incentivo.
            </Text>
            <Text component="p">
              <strong>
                O beneficiário poderá realizar o cashout via Pix ou TED para uma 
                conta de sua titularidade.
              </strong>
            </Text>
          </TextContent>
          <ImgContent highlight>
            <Img
              style={{flex: 1}}
              imgStyle={{objectFit: "contain"}}
              fluid={
                paymentMethodList.allFile.edges[3].node.childImageSharp.fluid
              }
            />
          </ImgContent>
        </Content>
        <Content variant="type2">
          <TextContent>
            <HeadingContent component="h2">
              Saia do escuro e tenha mais controle na gestão
            </HeadingContent>
            <Text component="p">
              Crie e gerencie incentivos em uma plataforma pensada para
              facilitar o dia a dia, no acesso ao controle financeiro,
              conciliações de extratos e histórico dos incentivos.
            </Text>
          </TextContent>
          <ImgContent>
            <Img
              fluid={
                paymentMethodList.allFile.edges[1].node.childImageSharp.fluid
              }
            />
          </ImgContent>
        </Content>

        <Content variant="type3">
          <SubHeading component="h1">O incentivo em cascata</SubHeading>

          <CardContent>
            <Card style={{ paddingTop: "32px" }}>
              <Suitcase />

              <TextContent style={{ maxWidth: "380px" }}>
                <HeadingContent component="h2">
                  é bom para sua agência
                </HeadingContent>

                
                <Text component="p">
                  Mais segurança, velocidade e escalabilidade para gestão e pagamento 
                  dos incentivos.
                </Text>
                <Text component="p">
                  Proporcione inovação para os seus clientes com a tecnologia do{" "}
                  <strong>WebVoucher.</strong>{" "}
                </Text>
                <Text component="p">
                  Forneça a melhor experiência de resgate e aproxime os beneficiários 
                  de sua marca.
                </Text>
                <Text component="p">
                  Personalize o Layout para cada evento ou incentivo.
                </Text>
              </TextContent>
            </Card>

            <Card>
              <Person />

              <TextContent>
                <HeadingContent component="h2">
                  é bom para o beneficiário
                </HeadingContent>

                <Text component="p">
                  Saque em tempo real <strong>via Pix.</strong>
                </Text>

                <Text component="p">
                  Agilidade na distribuição via link no <strong>WhatsApp.</strong>
                </Text>

                <Text component="p">
                  Os incentivos ficam a poucos cliques do beneficiário.
                </Text>

                <Text component="p">
                  Acesso via navegador, sem precisar criar conta ou baixar um 
                  aplicativo.
                </Text>

                <Text component="p">
                  Dúvidas podem ser tiradas diretamente no <strong>WhatsApp</strong> através de 
                  respostas instantâneas com o bot de atendimento.
                </Text>
              </TextContent>
            </Card>
          </CardContent>
        </Content>
      </TransitionBg>
    </Wrapper>
  );
};

export default PaymentMethods;
