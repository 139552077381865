import React from "react";

import OldMethod from "./old";
import NewMethod from "./new";

import { HeadingContent } from "./styles";

const Timeline = () => {
  return (
    <>
      <HeadingContent component="h4">
        Método ultrapassado de premiação com cartões
      </HeadingContent>
      <OldMethod />

      <HeadingContent component="h4" highlight>
        Método Fitce de premiação com WebVoucher
      </HeadingContent>
      <NewMethod />
    </>
  );
};

export default Timeline;
