import React, { FC, ReactNode, useState } from "react";
import { StaticImage } from "gatsby-plugin-image";

import {
  ArrowClick,
  ArrowDownIcon,
  ContentWrapper,
  Description,
  HeadingStyle,
  TitleWrapper,
  Wrapper,
} from "./styles";

interface IAccordion {
  title: string;
  children: ReactNode;
  maxH?: number;
  maxHMobile?: number;
}

const Accordion: FC<IAccordion> = ({ title, children, maxH, maxHMobile }) => {
  const [hasOpen, setHasOpen] = useState(false);

  return (
    <Wrapper hasOpen={hasOpen} maxH={maxH} maxHMobile={maxHMobile}>
      <TitleWrapper onClick={() => setHasOpen(!hasOpen)}>
        <HeadingStyle component="h5">{title}</HeadingStyle>

        <ArrowClick hasOpen={hasOpen}>
          <ArrowDownIcon />
        </ArrowClick>
      </TitleWrapper>
      <ContentWrapper>
        <Description component="span">{children}</Description>
      </ContentWrapper>
    </Wrapper>
  );
};

export default Accordion;
