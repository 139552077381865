import React from "react";

import {
  ArrowDropdownStyledMobie,
  ArrowRightStyle,
  OrientationWrapper,
  CardItem,
  TextStyled,
  BagIcon,
  ContactIcon,
  MobileFriendlyIcon,
  PriceCheckIcon,
  PixIcon
} from "../styles";

import { CardsWrapper, DropdownWrapperMobile } from "./styles";

const NewMethod = () => {
  return (
    <OrientationWrapper>
      <CardsWrapper>
        <CardItem>
          <BagIcon highlight />
          <TextStyled component="span">Contratação do serviço</TextStyled>
        </CardItem>
        <div>
          <ArrowRightStyle />
        </div>
        <CardItem>
          <ContactIcon highlight />
          <TextStyled component="span">
            Recebimento dos dados dos beneficiários
          </TextStyled>
        </CardItem>
        <div>
          <ArrowRightStyle />
        </div>
        <CardItem>
          <MobileFriendlyIcon highlight />
          <TextStyled component="span">
            Distribuição do incentivo de forma digital
          </TextStyled>
        </CardItem>
        <div>
          <ArrowRightStyle />
        </div>
        <CardItem>
          <PriceCheckIcon highlight />
          <TextStyled component="span">
            Beneficiário acessa o incentivo
          </TextStyled>
        </CardItem>
        <div>
          <ArrowRightStyle />
        </div>
        <CardItem>
          <PixIcon highlight />
          <TextStyled component="span">
            Beneficiário faz o Cashout (Pix ou TED)
          </TextStyled>
        </CardItem>
      </CardsWrapper>
      
      <DropdownWrapperMobile>
        <ArrowDropdownStyledMobie />
      </DropdownWrapperMobile>
      <DropdownWrapperMobile>
        <ArrowDropdownStyledMobie />
      </DropdownWrapperMobile>
    </OrientationWrapper>
  );
};

export default NewMethod;
