import styled from "styled-components";
import Heading from "../../components/heading";
import { COLORS } from "../../constants/colors";

export const Wrapper = styled.section``;

export const Content = styled.div`
  background-color: ${COLORS.WHITE};
  width: 100%;
  max-width: 636px;
  box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.25);
  padding: 48px;
  z-index: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 48px;
  transition: ease 0.2s;

  ${({ theme }) => theme.media.x`
    gap: 32px;
  `}

  ${({ theme }) => theme.media.md`
    gap: 24px;
    margin: 24px;

  `}
`;

export const HeadingStyle = styled(Heading)`
  color: ${COLORS.ORANGE};
  max-width: 402px;
  font-weight: 400;
`;

export const Anchor = styled.a`
  color: ${COLORS.GRAY.MAIN};
  border-bottom: 1px solid ${COLORS.GRAY.MAIN};
  font-style: normal;
  font-weight: 400;
  font-size: 1.5em;
  line-height: 28px;
  cursor: pointer;

  &:hover {
    filter: brightness(0.9);
  }

  ${({ theme }) => theme.media.x`
    font-size: 1.35em;
    line-height: 24px;
  `}

  ${({ theme }) => theme.media.md`
    font-size: 1.25em;
  `}
`;
