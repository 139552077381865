import React from "react";
import Accordion from "../../components/accordion";

import { ContentWrapper, HeadingStyle, Wrapper } from "./styles";

const FAQ = () => {
  return (
    <Wrapper id="faq">
      <HeadingStyle component="h1">Perguntas frequentes</HeadingStyle>
      <ContentWrapper>
        <Accordion title="O que é a Fitce?" maxH={176}>
          Fitce é um fluxo ágil de pagamento de incentivos. A Fitce nasceu para
          desburocratizar e reduzir custos de operação no mercado de incentivos
          através de tecnologias modernas que facilitam processos e automatizam
          rotinas de tesouraria.
        </Accordion>
        <Accordion title="O que é o WebVoucher?" maxH={176}>
          O WebVoucher é uma aplicação web (sem necessidade de download, pois roda
          no navegador web. Ex.: Google Chrome, Safari, Mozilla Firefox) que é
          acessada através de um link contido em uma mensagem de ativação via
          WhatsApp proveniente de um pagamento de incentivos.
        </Accordion>
        <Accordion title="O que é o Digital Cash Manager?" maxH={176}>
          O Digital Cash Manager tem como principal objetivo a gestão e execução 
          dos pagamentos de incentivos e premiações. A visão pode ser customizada 
          através de filtros e também podem ser criados e/ou editados pagamentos 
          de incentivos, seja através do upload de planilha ou via utilização de API.
        </Accordion>
        <Accordion title="O que é a Plataforma Fitce?" maxH={202}>
          A Plataforma Fitce tem como principal objetivo a administração e
          visualização dos números gerais dos incentivos e beneficiários. A
          visão pode ser customizada através de filtros na parte superior e
          também podem ser criados e/ou editados pagamentos de incentivos, seja
          através do upload de planilha, envio de incentivos para vários
          beneficiários de uma vez só, ou via API utilizando o identificador.
        </Accordion>
        <Accordion title="Qual o valor mínimo que posso operar?" maxH={130}>
          Não há valor mínimo para começar a utilizar os serviços Fitce.
        </Accordion>
        <Accordion title="Como posso contratar a Fitce?" maxH={130}>
          Para contratar ou tirar dúvidas sobre os serviços Fitce, fale conosco.
        </Accordion>
        <Accordion title="Como é a precificação do serviço Fitce?" maxH={130}>
          Os serviços Fitce são cobrados por cada transação. Para saber mais 
          sobre os custos, fale conosco.
        </Accordion>
      </ContentWrapper>
    </Wrapper>
  );
};

export default FAQ;
