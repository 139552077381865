import styled, { css } from "styled-components";
import Button from "../../components/button";
import Heading from "../../components/heading";

import { COLORS } from "../../constants/colors";

export const Wrapper = styled.section``;

export const Title = styled(Heading)`
  font-style: normal;
  font-weight: 300;
  font-size: 5em;
  line-height: 95px;
  color: ${COLORS.WHITE};
  transition: ease 0.2s;

  ${({ theme }) => theme.media.x`
    font-size: 3.2em;
    line-height: 68px;
  `}

  ${({ theme }) => theme.media.md`
    font-size: 2.5em;
    line-height: 47px;
  `}
`;
export const BoldTitle = styled(Heading)`
  font-style: normal;
  font-weight: 700;
  font-size: 6em;
  line-height: 114px;
  color: ${COLORS.WHITE};
  transition: ease 0.2s;

  ${({ theme }) => theme.media.x`
    font-size: 4em;
    line-height: 68px;
  `}

  ${({ theme }) => theme.media.md`
    font-size: 2.93em;
    line-height: 47px;
  `}
`;

export const HomeContent = styled.div`
  width: 100%;
  max-width: 829px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: ease 0.2s;
  z-index: 1;

  ${({ theme }) => theme.media.x`
    margin-top: 60px;
  `}
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 48px;

  ${({ theme }) => theme.media.x`
    gap: 32px;
    margin: 0 24px;
  `}

  ${({ theme }) => theme.media.md`
    gap: 24px;
  `}
`;

export const Divider = styled.div`
  width: 100%;
  max-width: 150px;
  border: 1px solid ${COLORS.WHITE};
  margin-top: 48px;

  ${({ theme }) => theme.media.x`
    gap: 32px;
  `}

  ${({ theme }) => theme.media.md`
    gap: 24px;
  `}
`;

export const Description = styled.span`
  font-style: normal;
  font-weight: 300;
  font-size: 1.5em;
  line-height: 170%;
  color: ${COLORS.WHITE};
  transition: ease 0.2s;

  ${({ theme }) => theme.media.x`
    font-size: 1.35em;
    line-height: 40.8px;
  `}

  ${({ theme }) => theme.media.md`
    font-size: 1.25em;
    line-height: 34px;
  `}
`;

export const ActionButton = styled(Button)`
  box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.25);
`;

export const AboutWrapper = styled.div`
  background-color: ${COLORS.WHITE};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 40px 0;

  ${({ theme }) => theme.media.md`
    flex-direction: column;
    align-items: flex-end;
  `}
`;

export const AboutContent = styled.div`
  width: 100%;
  max-width: 547.63px;
  display: flex;
  flex-direction: column;
  gap: 32px;

  ${({ theme }) => theme.media.xxl`
    margin-left: 8vw;
  `}

  ${({ theme }) => theme.media.xl`
    margin-left: 9vw;
  `}

  ${({ theme }) => theme.media.x`
    margin-left: 12vw;
  `}

  ${({ theme }) => theme.media.md`
    max-width: 900px;
    padding: 0 24px;
  `}
`;

export const AboutTitle = styled(Heading)`
  max-width: 525px;
  color: ${COLORS.ORANGE};
`;

export const ButtonWrapper = styled.div`
  margin-top: 16px;
`;

export const ScheduleConversationButton = styled.a`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: ${COLORS.ORANGE};
  color: ${COLORS.WHITE};

  height: 56px;

  text-align: -webkit-center;
  padding: 16px 24px;

  &:hover {
    filter: none;
  }
`;

export const ImageContent = styled.div`
  width: inherit;
  max-width: 38vw;

  ${({ theme }) => theme.media.md`
    margin-top: 24px;
    max-width: 96vw;
  `}
`;
