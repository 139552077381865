import styled, { css } from "styled-components";
import Button from "../../components/button";
import Heading from "../../components/heading";

import { COLORS } from "../../constants/colors";

interface IContent {
  variant?: "type1" | "type2" | "type3" | "type4" | "type5";
}

export const Wrapper = styled.section`
  width: 100%;
  background: linear-gradient(
    172deg,
    #f7f7f7 85%,
    #f7f7f7 62.2%,
    rgb(255 255 255 / 0%) 62%,
    rgb(255 255 255 / 0%) 100%
  );
  background-position-y: 11rem;
  transition: all 0.2s;
  ${({ theme }) => theme.media.md`
    background: linear-gradient(
      354deg,
      #f7f7f7 85.8%,
      #f7f7f7 62.2%,
      rgb(255 255 255 / 0%) 62%,
      rgb(255 255 255 / 0%) 100%
    );
    background-position-y: -11rem;
  `}
`;

export const BGTransition = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: all 0.2s;
  background: linear-gradient(
    355deg,
    #f7f7f7 85%,
    #86868626 62.2%,
    rgb(255 255 255 / 0%) 68%,
    rgb(255 255 255 / 0%) 100%
  );
  background-position-y: -15rem;

  padding: 156px 0 0 0;

  gap: 76px;

  ${({ theme }) => theme.media.lg`
    background-position-y: -27rem;
  `}

  ${({ theme }) => theme.media.md`
    background-position-y: -35rem;
  `}

  ${({ theme }) => theme.media.sm`
    background-position-y: -38rem;
  `}
`;

export const Content = styled.div<IContent>`
  width: 100%;
  max-width: 1200px;
  padding: 0 24px;
  transition: ease 0.2s;

  ${({ variant }) =>
    variant === "type1" &&
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    `};

  ${({ variant }) =>
    variant === "type2" &&
    css`
      /* max-width: 980px; */
      max-width: 1060px;
      align-items: center;
  
    `};

  ${({ variant }) =>
    (variant === "type3" || variant === "type4") &&
    css`
      align-items: center;
      display: flex;
      gap: 44px;
    `};

  ${({ variant }) => variant === "type5" && css``};

  ${({ theme, variant }) => theme.media.x`
    max-width: 1060px;
   
    ${
      variant === "type2" &&
      css`
        text-align: -webkit-center;
        max-width: 1100px;
      `
    };
  `}

  ${({ theme, variant }) => theme.media.md`
    
    ${
      variant === "type1" &&
      css`
        text-align: center;
      `
    };

    ${
      variant === "type3" &&
      css`
        flex-direction: column;
      `
    };

    ${
      variant === "type4" &&
      css`
        flex-direction: column-reverse;
      `
    };

    ${
      variant === "type5" &&
      css`
        padding: 0;
      `
    };
    
  `}
`;

export const HeadingStyle = styled(Heading)<{ highlight?: boolean }>`
  color: ${COLORS.ORANGE};

  ${({ highlight }) =>
    highlight &&
    css`
      width: fit-content;
      color: ${COLORS.WHITE};
      background-color: ${COLORS.ORANGE};
      padding: 8px;
    `};
`;

export const TitleWrapper = styled.div`
`;

export const HeadingHighlight = styled.strong`
  width: fit-content;
  color: ${COLORS.WHITE};
  background-color: ${COLORS.ORANGE};
  font-style: normal;
  font-weight: 400;
  line-height: 47px;

  ${({ theme }) => theme.media.x`
    line-height: 40px;
  `}

  ${({ theme }) => theme.media.md`
    line-height: 37px;
  `}
`;

export const Subtitle = styled(Heading)`
  font-family: "Inter";
  font-weight: 700;
`;

export const TextContent = styled.div`
  width: 100%;
  max-width: 550px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 32px;
  margin-top: 32px;
`;

export const ListWrapper = styled.ul`
  padding-left: 1.6em;
`;
export const ListItem = styled.li`
  color: ${COLORS.GRAY.MAIN};
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 150%;

  ${({ theme }) => theme.media.md`
    font-size: 1em;
  `}
`;

export const TextHighlight = styled.span`
  color: ${COLORS.ORANGE};
`;

export const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const ImageWrapper = styled.div`
  width: 100%;
`;

export const ContactWrapper = styled.div`
  display: flex;
  border-radius: 16px;
  background-color: ${COLORS.ORANGE};
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
  padding: 32px;
  gap: 32px;
  align-items: center;
  transition: ease 0.2s;

  ${({ theme }) => theme.media.md`
    flex-direction: column;
    border-radius: 0;
  `}
`;

export const ContactText = styled(Heading)`
  color: ${COLORS.WHITE};
  font-size: 2em;
  line-height: 37.92px;
`;

export const ActionButton = styled(Button)`
  background-color: ${COLORS.WHITE};
  color: ${COLORS.ORANGE};
`;
