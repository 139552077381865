import styled from "styled-components";

export const CardsWrapper = styled.div`
  width: 100%;
  max-width: 980px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 10px;
  row-gap: 80px;

  div:nth-child(10) {
    display: none;
  }

  @media (max-width: 1005px) {
    max-width: 866px;
    div:nth-child(8) {
      display: none;
    }

    div:nth-child(10) {
      display: block;
    }

    div:nth-child(12) {
      display: none;
    }
  }

  @media (max-width: 803px) {
    max-width: 660px;
    div:nth-child(6) {
      display: none;
    }

    div:nth-child(8) {
      display: block;
    }

    div:nth-child(10) {
      display: block;
    }
  }

  ${({ theme }) => theme.media.lg`
    max-width: 434px;
    div:nth-child(4) {
      display: none;
    }
    div:nth-child(6) {
      display: block;
    }
    div:nth-child(8) {
      display: none;
    }
    div:nth-child(9) {
      position: relative;
      left: -96px;
    }
  `};
`;

export const DropdownWrapper = styled.div`
  position: absolute;

  width: 100%;
  max-width: 960px;
  display: flex;
  justify-content: center;
  
  &:nth-child(3) {
    display: none;
  }

  @media (max-width: 1005px) {
    max-width: 806px;
  }

  @media (max-width: 803px) {
    max-width: 606px;

    &:nth-child(2) {
      svg {
        max-width: 216px;
        margin-left: 260px;
      }
    }

    &:nth-child(3) {
      display: block;
      bottom: 104px;

      svg {
        max-width: 290px;
        margin-left: 162px;
      }
    }
  }

  ${({ theme }) => theme.media.lg`
    display: none;

  `};
`;

export const DropdownWrapperMobile = styled.div`
  position: absolute;
  display: none;

  ${({ theme }) => theme.media.lg`
    display: block;
    right: 0;
    left: 0;
    top: 104px;

    &:nth-child(3) {
      top: 282px;
    }

    &:nth-child(6) {
      top: 458px;
    }
  `};
`;
