import React from "react";

import Layout from "../components/layout";

import Home from "../views/home";
import Solutions from "../views/solutions";
import PaymentMethods from "../views/paymentMethods";
import Contact from "../views/contact";
import FAQ from "../views/faq";

const IndexPage = () => {

  return (
    <Layout>
      <Home />
      <Solutions />
      <PaymentMethods />
      <Contact />
      <FAQ />
    </Layout>
  );
};

export default IndexPage;
